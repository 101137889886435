import React from 'react';

import Layout from '../../../../components/MenuLayout';
import { GroupMembers } from '../../../../components/Hospital/GroupManagement/GroupMembers';

export default ({ groupId }) => (
  <Layout type="settings">
    <GroupMembers groupId={groupId} />
  </Layout>
);
