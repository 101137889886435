import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { navigate } from 'gatsby';

import { Add, ArrowDropDown, ArrowDropUp, Backspace, Close, Delete, Sort } from '@material-ui/icons';
import { Container, Menu, MenuItem } from '@material-ui/core';
import { toast } from 'react-toastify';
import { isEmpty, sortGraphQueryList } from '../../../utils/ObjectUtils';
import { PageContent } from '../../Common/styled/PageContent';
import { Navbar } from '../../Navbar/styled/NavbarStyles';
import Loading from '../../Common/Loading';
import { GET_HOSPITAL_GROUPS_FOR_EDITING, GET_HOSPITAL_GROUPS_FOR_PREVIEW } from '../../../graphql/queries';
import { SectionBar } from '../../../componentsUI/SectionBar';
import { NewGroupMemberDialog } from './modal/NewGroupMemberDialog';
import { CardWrapperUI } from '../../Common/styled/CardWrapperUI';
import TableCollapse from '../../../componentsUI/tableCollapse';
import { NoMembers } from '../../Common/GroupCard/GroupMemberCard';
import { DELETE_USER_GROUP_MEMBER } from '../../../graphql/mutations';
import { DeleteGroupMemberDialog } from './modal/DeleteGroupMemberDialog';
import { AvailableTeamMembers } from '../../../utils/HospitalMemberUtils';

const fieldNames = [
  { label: 'name', id: 'user.name', width: 120, field: 'NAME', direction: 'ASC' },
  { label: 'surname', id: 'user.surname', width: 120, field: 'SURNAME', direction: 'ASC' },
  { label: 'email', id: 'user.email', width: 175, field: 'EMAIL', direction: 'ASC' },
];

export const GroupMembers = ({ groupId }) => {
  const { t } = useTranslation();
  const uuid = useSelector((state) => state.hospital.uuid);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState([]);
  const [showNewModal, setShowNewModal] = useState(false);
  const openMenu = Boolean(anchorEl);

  const sortData = {
    default: { field: 'NAME', direction: 'ASC' },
    fields: [{ key: 'NAME', text: 'Name' }, { key: 'SURNAME', text: 'Surname' }, { key: 'EMAIL', text: 'Email' }],
  };

  const [sortByField, setSortingField] = useState(sortData.default.field);
  const [sortByDirection, setSortingDirection] = useState(sortData.default.direction);

  const { loading, data, refetch } = useQuery(
    GET_HOSPITAL_GROUPS_FOR_EDITING, {
      variables: {
        uuid,
      },
    },
  );

  const [deleteUserGroupMember, { loading: deleting }] = useMutation(DELETE_USER_GROUP_MEMBER, {
    onCompleted: () => {
      toast('Member removed.', { className: 'toast-success' });
    },
    onError: () => {
      toast('There was an error removing the member. Please try again.', { className: 'toast-error' });
    },
    refetchQueries: [{ query: GET_HOSPITAL_GROUPS_FOR_PREVIEW, variables: { uuid } }],
    awaitRefetchQueries: true,
  });

  const reverseDirection = () => (sortByDirection === 'ASC' ? 'DESC' : 'ASC');

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === sortByField) {
        setSortingDirection(reverseDirection());
      } else {
        setSortingDirection(value.direction);
      }

      setSortingField(value.field);
      setAnchorEl();
      setSelected([]);
    }
  };

  const getTitle = (tm) => (
    `${tm.user.name} ${tm.user.surname}`
  );

  const getSubTitle = (tm) => (
    `${tm.user.email}`
  );

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNew = () => {
    setShowNewModal(false);
    refetch().then();
  };

  const handleDelete = (member) => {
    deleteUserGroupMember({ variables: { userGroupMemberUuid: member.uuid } }).then();
  };

  const tableRowConfigureButton = {
    Icon: Backspace,
    tooltip: 'delete.member',
    inProgress: deleting && selected[0],
  };

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDown className={className} /> : <ArrowDropUp className={className} />
  );

  const group = data && data.hospital && data.hospital.userGroups.find((userGroup) => userGroup.uuid === groupId);
  const teamMembers = data && data.hospital && AvailableTeamMembers(data.hospital);

  const memberList = group && sortGraphQueryList(group.groupMembers, fieldNames, sortByField, sortByDirection);

  if (data && data.hospital && !group) navigate('/hospital/settings/group');

  const handleGoBack = (event) => {
    event.stopPropagation();
    navigate('/hospital/settings/group');
  };

  const handleDeleteByIndex = (event, index) => {
    event.stopPropagation();
    handleDelete(memberList && index !== undefined && memberList[index]);
  };

  const handleDeleteSelected = (event) => {
    event.stopPropagation();
    handleDelete(memberList && selected.length > 0 && memberList[selected[0]]);
  };

  const disableAddGroupMemberButtonCondition = group && group.groupMembers.length && (group.groupMembers.length !== 0 && group.groupMembers.length === teamMembers.length);
  const disableDeleteGroupMemberButtonCondition = !selected.length;

  const buttons = [
    { name: 'add.member', icon: Add, handleClick: () => setShowNewModal(true), disabled: !!disableAddGroupMemberButtonCondition },
    { name: 'delete.member', icon: Delete, handleClick: handleDeleteSelected, disabled: !!disableDeleteGroupMemberButtonCondition },
    { name: 'divider2', type: 'divider' },
    { name: 'go.back', icon: Close, handleClick: handleGoBack, disabled: false },
    { name: 'sort.by', icon: Sort, handleClick: handleOrder, disabled: false },
  ];

  return (
    <>
      <Navbar>
        <NewGroupMemberDialog open={showNewModal} onClose={handleCloseNew} group={group} teamMembers={teamMembers} />
        <DeleteGroupMemberDialog open={deleting} />

        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === sortByField) && <OrderIcon direction={sortByDirection} />}
            </MenuItem>
          ))}
        </Menu>

        <SectionBar title="edit.group" items={buttons} subtitle={group && group.name} />
      </Navbar>
      <Container maxWidth="lg">
        <PageContent>
          {loading && isEmpty(data) ? <Loading /> : (
            <CardWrapperUI>
              {memberList ? (
                <TableCollapse
                  fieldNames={fieldNames}
                  items={memberList}
                  GetTitle={getTitle}
                  GetSubtitle={getSubTitle}
                  selected={selected}
                  setSelected={setSelected}
                  rowActionButton={tableRowConfigureButton}
                  handleGoto={handleDeleteByIndex}
                  order={{ field: sortByField, direction: sortByDirection }}
                  handleOrder={handleOrderBy}
                />
              ) : <NoMembers />}
            </CardWrapperUI>
          )}
        </PageContent>
      </Container>
    </>
  );
};
